import { callExternalApi } from "./external-api.service";

const apiServerUrl = 'http://localhost:6060';

export const getVerifyAdmin = async (accessToken) => {
  const config = {
    url: `${apiServerUrl}/api/admin/verify`,
    method: "GET",
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
  };

  const { data, error } = await callExternalApi({ config });
  return {
    data: data || null,
    error,
  };
};

export const getMuclegroups = async (accessToken) => {
  const config = {
    url: `${apiServerUrl}/api/admin/musclegroups/all`,
    method: "GET",
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
  };

  const { data, error } = await callExternalApi({ config });
  return {
    data: data || null,
    error,
  };
};

export const postMusclegroup = async (accessToken,musclegroupobj) => {
  const config = {
    url: `${apiServerUrl}/api/admin/musclegroup/add`,
    method: "POST",
    data: musclegroupobj,
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
  };

  const { data, error } = await callExternalApi({ config });
  return {
    data: data || null,
    error,
  };
};

export const removeMusclegroup = async (accessToken,musclegroupobj) => {
  const config = {
    url: `${apiServerUrl}/api/admin/musclegroup/remove`,
    method: "POST",
    data: musclegroupobj,
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
  };

  const { data, error } = await callExternalApi({ config });
  return {
    data: data || null,
    error,
  };
};


export const getExercises = async (accessToken) => {
  const config = {
    url: `${apiServerUrl}/api/admin/exercises/all`,
    method: "GET",
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
  };

  const { data, error } = await callExternalApi({ config });
  return {
    data: data || null,
    error,
  };
};

export const postExercise = async (accessToken,exerciseobj) => {
  const config = {
    url: `${apiServerUrl}/api/admin/exercise/add`,
    method: "POST",
    data: exerciseobj,
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
  };

  const { data, error } = await callExternalApi({ config });
  return {
    data: data || null,
    error,
  };
};

export const removeExercise = async (accessToken,exerciseobj) => {
  const config = {
    url: `${apiServerUrl}/api/admin/exercise/remove`,
    method: "POST",
    data: exerciseobj,
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
  };

  const { data, error } = await callExternalApi({ config });
  return {
    data: data || null,
    error,
  };
};

export const getScoretypes = async (accessToken) => {
  const config = {
    url: `${apiServerUrl}/api/admin/scoretypes/all`,
    method: "GET",
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
  };

  const { data, error } = await callExternalApi({ config });
  return {
    data: data || null,
    error,
  };
};

export const postScoretype = async (accessToken,scoretypeobj) => {
  const config = {
    url: `${apiServerUrl}/api/admin/scoretype/add`,
    method: "POST",
    data: scoretypeobj,
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
  };

  const { data, error } = await callExternalApi({ config });
  return {
    data: data || null,
    error,
  };
};

export const removeScoretype = async (accessToken,scoretypeobj) => {
  const config = {
    url: `${apiServerUrl}/api/admin/scoretype/remove`,
    method: "POST",
    data: scoretypeobj,
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
  };

  const { data, error } = await callExternalApi({ config });
  return {
    data: data || null,
    error,
  };
};

export const getWodcategories = async (accessToken) => {
  const config = {
    url: `${apiServerUrl}/api/admin/wodcategories/all`,
    method: "GET",
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
  };

  const { data, error } = await callExternalApi({ config });
  return {
    data: data || null,
    error,
  };
};

export const postWodcategory = async (accessToken,wodcategoryobj) => {
  const config = {
    url: `${apiServerUrl}/api/admin/wodcategory/add`,
    method: "POST",
    data: wodcategoryobj,
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
  };

  const { data, error } = await callExternalApi({ config });
  return {
    data: data || null,
    error,
  };
};

export const removeWodcategory = async (accessToken,wodcategoryobj) => {
  const config = {
    url: `${apiServerUrl}/api/admin/wodcategory/remove`,
    method: "POST",
    data: wodcategoryobj,
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
  };

  const { data, error } = await callExternalApi({ config });
  return {
    data: data || null,
    error,
  };
};

export const getWorkouts = async (accessToken) => {
  const config = {
    url: `${apiServerUrl}/api/admin/workouts/all`,
    method: "GET",
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
  };

  const { data, error } = await callExternalApi({ config });
  return {
    data: data || null,
    error,
  };
};

export const postWorkout = async (accessToken,workoutobj) => {
  const config = {
    url: `${apiServerUrl}/api/admin/workout/add`,
    method: "POST",
    data: workoutobj,
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
  };

  const { data, error } = await callExternalApi({ config });
  return {
    data: data || null,
    error,
  };
};

export const removeWorkout = async (accessToken,workoutobj) => {
  const config = {
    url: `${apiServerUrl}/api/admin/workout/remove`,
    method: "POST",
    data: workoutobj,
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
  };

  const { data, error } = await callExternalApi({ config });
  return {
    data: data || null,
    error,
  };
};

export const addAliases = async (accessToken,exerciseobj) => {
  const config = {
    url: `${apiServerUrl}/api/admin/exercise/aliases/add`,
    method: "POST",
    data: exerciseobj,
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
  };

  const { data, error } = await callExternalApi({ config });
  return {
    data: data || null,
    error,
  };
};