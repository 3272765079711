<script>
  import NavBar from "@/components/nav-bar";
  import RightBar from "@/components/right-bar";
  import Footer from "@/components/footer";
  import { getVerifyAdmin } from "@/services/admin.service";
  import { useAuth0 } from "@auth0/auth0-vue";
  import { state } from "@/state/modules/layout.js";
  
  export default {

    setup() {
    },
    data() {
      return {
        isAdmin: false
    };
    },
    watch: {
      $route: {
        handler: "onRoutechange",
        immediate: true,
        deep: true,
      },
    },
    methods: {
      async checkIfUserIsAdmin() {
          //var result = false;
          const { getAccessTokenSilently } = useAuth0();
          const accessToken = await getAccessTokenSilently();
          const { data, error } = await getVerifyAdmin(accessToken);
          if (data) {
            console.log("ok");
            this.isAdmin = true;
          }
          if (error) {
            console.log("not ok");
            this.isAdmin = false;
          }
          console.log(this.isAdmin);
      },
      onRoutechange(ele) {
        this.initActiveMenu(ele.path);
      },
      initActiveMenu(ele) {
        setTimeout(() => {
          if (document.querySelector("#navbar-nav")) {
            let a = document
              .querySelector("#navbar-nav")
              .querySelector('[href="' + ele + '"]');
  
            if (a) {
              a.classList.add("active");
              let parentCollapseDiv = a.closest(".collapse.menu-dropdown");
              if (parentCollapseDiv) {
                parentCollapseDiv.classList.add("show");
                parentCollapseDiv.parentElement.children[0].classList.add(
                  "active"
                );
                parentCollapseDiv.parentElement.children[0].setAttribute(
                  "aria-expanded",
                  "true"
                );
                if (
                  parentCollapseDiv.parentElement.closest(
                    ".collapse.menu-dropdown"
                  )
                ) {
                  parentCollapseDiv.parentElement
                    .closest(".collapse")
                    .classList.add("show");
                  if (
                    parentCollapseDiv.parentElement.closest(".collapse")
                      .previousElementSibling
                  )
                    parentCollapseDiv.parentElement
                      .closest(".collapse")
                      .previousElementSibling.classList.add("active");
                }
              }
            }
          }
        }, 1000);
      },
    },
    mounted() {
      this.checkIfUserIsAdmin();
      switch (state.layoutType) {
      case "horizontal":
        document.documentElement.setAttribute("data-layout", "horizontal");
        break;
      case "vertical":
        document.documentElement.setAttribute("data-layout", "vertical");
        break;
      case "twocolumn":
        document.documentElement.setAttribute("data-layout", "twocolumn");
        break;
    }
    },
    components: {
      NavBar,
      RightBar,
      Footer
    },
  };
  </script>
  
  <template>
    <div>
      <div id="layout-wrapper">
        <NavBar />
        <!-- ========== App Menu ========== -->
        <div class="app-menu navbar-menu">
          <!-- LOGO -->
          <div class="navbar-brand-box">
            <!-- Dark Logo-->
            <router-link to="/" class="logo logo-dark">
              <span class="logo-sm">
                <img src="@/assets/images/logo-sm.png" alt="" height="22" />
              </span>
              <span class="logo-lg">
                <img src="@/assets/images/logo-dark.png" alt="" height="17" />
              </span>
            </router-link>
            <!-- Light Logo-->
            <router-link to="/" class="logo logo-light">
              <span class="logo-sm">
                <img src="@/assets/images/logo-sm.png" alt="" height="22" />
              </span>
              <span class="logo-lg">
                <img src="@/assets/images/logo-light.png" alt="" height="17" />
              </span>
            </router-link>
            <button type="button" class="
                btn btn-sm
                p-0
                fs-20
                header-item
                float-end
                btn-vertical-sm-hover
              " id="vertical-hover">
              <i class="ri-record-circle-line"></i>
            </button>
          </div>
          <div id="scrollbar">
            <div class="container-fluid">
              <ul class="navbar-nav h-100" id="navbar-nav">
                <li class="menu-title">
                  <span data-key="t-menu"> {{ $t("t-menu") }}</span>
                </li>
                <li class="nav-item">
                  <router-link to="/" class="nav-link" data-key="t-home">
                    <i class="ri-home-3-line"></i>
                    {{ $t("t-home") }}
                  </router-link>
                </li>
                <!-- end Home Menu -->
                <li class="nav-item">
                  <a class="nav-link menu-link" href="#workouts" data-bs-toggle="collapse" role="button"
                    aria-expanded="false" aria-controls="workouts">
                    <i class="ri-list-check"></i>
                    <span data-key="t-workouts"> {{ $t("t-workouts") }}</span>
                  </a>
                  <div class="collapse menu-dropdown" id="workouts">
                    <ul class="nav nav-sm flex-column">
                      <li class="nav-item">
                        <router-link to="/workout/add" class="nav-link" data-key="t-create-workout">
                          {{ $t("t-create-workout") }}
                        </router-link>
                      </li>
                      <li class="nav-item">
                        <router-link to="/workout/all" class="nav-link" data-key="t-show-workouts">
                          {{ $t("t-show-workouts") }}
                        </router-link>
                      </li>
                    </ul>
                  </div>
                </li>
                <!-- end Workouts Menu -->
                <li class="nav-item">
                  <a class="nav-link menu-link" href="#exercises" data-bs-toggle="collapse" role="button"
                    aria-expanded="false" aria-controls="exercises">
                    <i class="ri-heart-line"></i>
                    <span data-key="t-exercises"> {{ $t("t-exercises") }}</span>
                  </a>
                  <div class="collapse menu-dropdown" id="exercises">
                    <ul class="nav nav-sm flex-column">
                      <li class="nav-item">
                        <router-link to="/exercise/all" class="nav-link" data-key="t-show-all">
                          {{ $t("t-show-all") }}
                        </router-link>
                      </li>
                    </ul>
                  </div>
                </li>
                <!-- end Exercise Menu -->
                <li class="nav-item">
                  <a class="nav-link menu-link" href="#gyms" data-bs-toggle="collapse" role="button"
                    aria-expanded="false" aria-controls="gyms">
                    <i class="ri-home-heart-line"></i>
                    <span data-key="t-gyms"> {{ $t("t-gyms") }}</span>
                  </a>
                  <div class="collapse menu-dropdown" id="gyms">
                    <ul class="nav nav-sm flex-column">
                      <li class="nav-item">
                        <router-link to="/gym/all" class="nav-link" data-key="t-show-all">
                          {{ $t("t-show-all-gyms") }}
                        </router-link>
                      </li>
                      <li class="nav-item">
                        <router-link to="#" class="nav-link" data-key="t-join-a-gym">
                          {{ $t("t-join-a-gym") }}
                        </router-link>
                      </li>
                      <li class="nav-item">
                        <router-link to="#" class="nav-link" data-key="t-create-a-gym">
                          {{ $t("t-create-a-gym") }}
                        </router-link>
                      </li>
                    </ul>
                  </div>
                </li>
                <!-- end Gyms Menu -->
                <li class="nav-item">
                  <a class="nav-link menu-link" href="#gyms" data-bs-toggle="collapse" role="button"
                    aria-expanded="false" aria-controls="competitions">
                    <i class="bx bx-trophy"></i>
                    <span data-key="t-gyms">Competitions</span>
                  </a>
                  <div class="collapse menu-dropdown" id="gyms">
                    <ul class="nav nav-sm flex-column">
                      <li class="nav-item">
                        <router-link to="/gym/all" class="nav-link" data-key="t-show-all">
                          {{ $t("t-show-all-gyms") }}
                        </router-link>
                      </li>
                      <li class="nav-item">
                        <router-link to="#" class="nav-link" data-key="t-join-a-gym">
                          {{ $t("t-join-a-gym") }}
                        </router-link>
                      </li>
                      <li class="nav-item">
                        <router-link to="#" class="nav-link" data-key="t-create-a-gym">
                          {{ $t("t-create-a-gym") }}
                        </router-link>
                      </li>
                    </ul>
                  </div>
                </li>
                <!-- end Competitions Menu -->
                <li class="nav-item">
                  <a class="nav-link menu-link" href="#gyms" data-bs-toggle="collapse" role="button"
                    aria-expanded="false" aria-controls="competitions">
                    <i class="bx bx-user"></i>
                    <span data-key="t-gyms">Coaches</span>
                  </a>
                  <div class="collapse menu-dropdown" id="gyms">
                    <ul class="nav nav-sm flex-column">
                      <li class="nav-item">
                        <router-link to="/gym/all" class="nav-link" data-key="t-show-all">
                          {{ $t("t-show-all-gyms") }}
                        </router-link>
                      </li>
                      <li class="nav-item">
                        <router-link to="#" class="nav-link" data-key="t-join-a-gym">
                          {{ $t("t-join-a-gym") }}
                        </router-link>
                      </li>
                      <li class="nav-item">
                        <router-link to="#" class="nav-link" data-key="t-create-a-gym">
                          {{ $t("t-create-a-gym") }}
                        </router-link>
                      </li>
                    </ul>
                  </div>
                </li>
                <!-- end Trainers Menu -->
                <li v-if="isAdmin" class="nav-item">
                  <a class="nav-link menu-link" href="#admin" data-bs-toggle="collapse" role="button"
                    aria-expanded="false" aria-controls="admin">
                    <i class="ri-shield-flash-line"></i>
                    <span data-key="t-site-admin"> {{ $t("t-site-admin") }}</span>
                  </a>
                  <div class="collapse menu-dropdown" id="admin">
                    <ul class="nav nav-sm flex-column">
                      <li class="nav-item">
                        <router-link to="#" class="nav-link" data-key="t-dashboard">
                          {{ $t("t-dashboard") }}
                        </router-link>
                      </li>
                      <li class="nav-item">
                        <router-link to="/admin/scoretypes" class="nav-link" data-key="t-score-types">
                          {{ $t("t-score-types") }}
                        </router-link>
                      </li>
                      <li class="nav-item">
                        <router-link to="/admin/wodcategories" class="nav-link" data-key="t-wod-categories">
                          {{ $t("t-wod-categories") }}
                        </router-link>
                      </li>
                      <li class="nav-item">
                        <router-link to="/admin/workouts" class="nav-link" data-key="t-workouts">
                          {{ $t("t-workouts") }}
                        </router-link>
                      </li>
                      <li class="nav-item">
                        <router-link to="/admin/exercises" class="nav-link" data-key="t-exercises">
                          {{ $t("t-exercises") }}
                        </router-link>
                      </li>
                      <li class="nav-item">
                        <router-link to="/admin/musclegroups" class="nav-link" data-key="t-muscle-groups">
                          {{ $t("t-muscle-groups") }}
                        </router-link>
                      </li>
                    </ul>
                  </div>
                </li>
                <!-- end ADMIN Menu -->
              </ul>
            </div>
            <!-- Sidebar -->
          </div>
          <!-- Left Sidebar End -->
          <!-- Vertical Overlay-->
          <div class="vertical-overlay"></div>
        </div>
        <!-- ============================================================== -->
        <!-- Start Page Content here -->
        <!-- ============================================================== -->
  
        <div class="main-content">
          <div class="page-content">
            <!-- Start Content-->
            <div class="container-fluid">
              <slot />
            </div>
          </div>
          <Footer />
        </div>
        <RightBar />
      </div>
    </div>
  </template>